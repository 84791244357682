import React from 'react'
import { Typography } from '../../components/library'
import LibraryWrapper from './LibraryWrapper'

function typography() {
    return (
        <LibraryWrapper>
            <pre>Main title</pre>
            <Typography variant="h1" shade="dark">Sed ut perspiciatis unde omnis iste natus error</Typography>
            <Typography variant="h2" shade="light">Sed ut perspiciatis unde omnis iste natus error</Typography>
            <Typography variant="h3" align="left">Sed ut perspiciatis unde omnis iste natus error</Typography>
            <Typography variant="h4" color="primary" align="right">Sed ut perspiciatis unde omnis iste natus error</Typography>
            <Typography variant="h5" color="secondary" align="center">Sed ut perspiciatis unde omnis iste natus error</Typography>
            <Typography variant="h6" color="red" uppercase>Sed ut perspiciatis unde omnis iste natus error</Typography>
            <pre>Subtitle</pre>
            <Typography variant="subtitle1">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            </Typography>
            <pre>Subtitle 2</pre>
            <Typography variant="subtitle2" shade="dark">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            </Typography>
            <pre>Paragraph</pre>
            <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
            </Typography>
            <pre>Paragraph lead</pre>
            <Typography variant="body2" shade="dark">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
            </Typography>
            <pre>Paragraph small</pre>
            <Typography variant="caption">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
            </Typography>
        </LibraryWrapper>
    )
}

export default typography
