import React from 'react'
import { Link } from 'gatsby'
import ArrowBackIcon16 from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container'

function LibraryWrapper({children}) {
    return (
        <div id="components-library">
            <Container>
                <pre><Link to="/components"><ArrowBackIcon16 /> Back</Link></pre>
                {children}
            </Container>
        </div>
    )
}

export default LibraryWrapper
